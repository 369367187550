<template>
    <div>
        <div class="row">
            <div class="col-6">
                <h5>{{ $t('global.disponibles') }}</h5>
                <p>{{ $t('filtres.aide_drag_drop') }}</p>

                <b-form-input
                    class="col-auto"
                    ref="searchfield"
                    v-model="search_value"
                    type="text"
                    :placeholder="getTrad('global.recherchez_tableau')"
                    debounce="500"
                />
            </div>
            <div class="col-6">
                <h5>{{ $t('filtres.visible_dans_tableau') }}</h5>
            </div>
        </div>

        <div class="row mb-5">
            <div class="col-6 pr-0 pr-md-3">
                <draggable class="list-group list-group-left" :list="sorted_columns" v-bind="dragOptionsAllColumns" group="colonne">
                    <div class="list-group-item  d-md-flex align-items-center " v-for="(column, index) in sorted_columns" :key="'dispo_col_'+index">
                        {{ getColumnTrad(table_type, column) }}
                        <div class="btn btn-secondary ml-auto" @click="select_column(column)">
                            <font-awesome-icon :icon="['fal', 'long-arrow-right']" />
                        </div>
                    </div>
                </draggable>
            </div>
            <div class="col-6 pl-0 pl-md-3">
                <draggable class="list-group" :list="selected_columns" v-bind="dragOptionsSelectedColumns" group="colonne">
                    <div class="list-group-item d-md-flex align-items-center " v-for="(column, index) in selected_columns" :key="'select_col_'+index">
                        <div class="badge badge-pill badge-secondary mr-2">{{ index }}</div>
                        <div class="btn btn-secondary ml-auto order-md-last">
                            <font-awesome-icon :icon="['fal', 'sort-alt']" />
                        </div><br class="d-md-none"/> {{ getColumnTrad(table_type, column) }}
                    </div>
                </draggable>
            </div>
        </div>
    </div>
</template>


<script type="text/javascript">
    import TableMixin from '@/mixins/Table.js'
    import draggable from 'vuedraggable'

	export default {
        name: "TableConfigColumnView",
        mixins: [TableMixin],
        props: {
            table_type: String,
            available_cols: Array,
            selected_columns: Array,
        },
        data() {
            return {
                // Options du composant draggable
                dragOptionsAllColumns: {
                    sort: false
                },
                dragOptionsSelectedColumns: {
                    animation: 200
                },
                sorted_columns: [],
                search_value: null
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            init_component() {
                this.sort_columns()
            },
            sort_columns() {
                this.sorted_columns = []
                this.available_cols.forEach(col => {
                    this.sorted_columns.push(col)
                })

                let that = this
                this.sorted_columns.sort(function (a, b) {
                    return that.getColumnTrad(that.table_type, a).localeCompare(that.getColumnTrad(that.table_type, b))
                })
            },
            select_column(col) {
                const index = this.sorted_columns.indexOf(col)
                this.sorted_columns.splice(index, 1)
                this.selected_columns.push(col)
            },
            strNoAccent(str) {
                return str.normalize('NFD').replace(/([\u0300-\u036f])/g, '');
            }
        },
        watch: {
            search_value(val) {
                this.sort_columns()
                if(val) {
                    this.sorted_columns = this.sorted_columns.filter(col => this.strNoAccent(this.getColumnTrad(this.table_type, col).toLowerCase()).includes(this.strNoAccent(val.toLowerCase())))
                }
            }
        },
        components: {
            draggable
        }
	}

</script>
